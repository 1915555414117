<template>
  <section banner-card v-editable="blok">
    <div class="container">
      <div class="row">
        <div class="card">
          <div class="card-content">
            <h2 class="card-title" v-if="blok?.title && title != ''" v-html="title"></h2>
            <div class="card-description" v-if="blok?.description && description != ''" v-html="description"></div>
          </div>
          <div class="card-buttons">
            <StoryblokComponent v-for="blok in blok.buttons" :key="blok._uid" :blok="blok" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<style src="~/assets/scss/section/banner-card.scss" lang="scss"></style>
<script setup>
const props = defineProps({ blok: Object });
const title = computed(() => renderRichText(props?.blok?.title || {}));
const description = computed(() => renderRichText(props?.blok?.description || {}));
</script>
